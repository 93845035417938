import React, { FunctionComponent, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Content, StyledLink, Container } from './styled';
import { useScrollPosition } from '../../helpers/useScroll';

const Navbar: FunctionComponent = () => {
  const [isTop, setIsTop] = useState(true);

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y === 0;
      if (isShow !== isTop) setIsTop(isShow);
    },
    [isTop],
    undefined,
    false,
    100,
  );

  // eslint-disable-next-line no-undef
  const { file } = useStaticQuery<Queries.NavbarQueryQuery>(graphql`
    query NavbarQuery {
      file(name: { eq: "global.en" }) {
        childJson {
          navbar {
            logo {
              childImageSharp {
                gatsbyImageData(height: 36, placeholder: NONE)
              }
            }
            links {
              text
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Container $isTop={isTop}>
      <Content>
        {file?.childJson?.navbar?.logo?.childImageSharp?.gatsbyImageData && (
          <Link to="/">
            <GatsbyImage
              image={file.childJson.navbar.logo.childImageSharp.gatsbyImageData}
              alt="Verse Logo"
              style={{ height: 36, width: 'auto' }}
            />
          </Link>
        )}
        <div>
          {file?.childJson?.navbar?.links?.map(l => (
            <StyledLink
              key={l?.text}
              href={l?.url || '/'}
              download={l?.url?.endsWith('.pdf')}
            >
              {l?.text}
            </StyledLink>
          ))}
        </div>
      </Content>
    </Container>
  );
};

export default Navbar;
