module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bitcoin.com","short_name":"Bitcoin.com","start_url":"/","display":"fullscreen","background_color":"#fdfdfd","theme_color":"#111724","prefer_related_applications":true,"related_applications":[{"platform":"play","id":"com.bitcoin.mwallet"}],"icon":"static/icons/icon-512x512.png","include_favicon":false,"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":"482d00cf0f32858aa9b057950c997c92"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
